export default {
    api: 'https://backend-oceanofoods.grow-corporate.com/',
    // api: 'http://127.0.0.1:8000/',
    apitwo: '/',
    // apiNode: 'http://127.0.0.1:3001/',
    apiNode: 'https://express-backend-spider.grow-corporate.com/',
    basename: '/',
    nombreSistema : 'Oceano Foods',
    nombreSistemaHo : 'Oceano Foods',
    nombreCliente : 'los países Oceano Foods Perú',
};
